@import '../../variables.scss';

.redirectNoticeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
  div {
    @include Panel;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 16px;
  }
}

.QuotationLinesTable {
  border-spacing: 0;

  td,
  th {
    padding: 0.25em 0.5em;
  }

  tbody {
    tr:hover {
      background-color: $primary-lighter-12;
      color: $primary-lighter-12-text;
    }
  }
}

.Messages {
  display: flex;
  justify-content: center;
}

.PreviewQuoteBtn, .EmailQuoteBtn, .ContinueQuoteBtn, .DiscardQuoteBtn, .DuplicateQuoteBtn {
  padding: 0 16px 0 12px;
  margin: 5px;
  height: 22px;
  border-radius: 5px;
  font-family: 'Roboto Condensed';
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  border: none;
  gap: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  letter-spacing: 1px;

  &:hover {
    box-shadow: 0 5px 10px rgba(0,0,0,0.22);
    cursor: pointer;
  }
}
.PreviewQuoteBtn {
  background-color: $primary;
}
.EmailQuoteBtn {
  background-color: $primary-green;
}
.ContinueQuoteBtn {
  background-color: $primary-green;
}
.DiscardQuoteBtn {
  background-color: $primary-red;
}
.ContinueQuoteBtn:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.DuplicateQuoteBtn {
  background-color: $primary-orange;
}
.AddVoipItemsBtn {
  grid-column-start: 3;
  grid-column-end: 3;
  background-color: $primary-yellow;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 16px 0 12px;
  border: none;
  
}
.AddAdditionalServicesItemsBtn {
  grid-column-start: 3;
  grid-column-end: 3;
  background-color: $primary-teal;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 16px 0 12px;
  border: none;
  
}
.modal {
  display: none;
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4);
}
.modalContent {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 25%; 
  text-align: center;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.PopupHeader {
  color: #0081c6;
}
.error {
  color: $primary-red;
}

.OtpEmailBtn {
  background-color: #005e91;
  padding: 0 16px 0 12px;
  height: 36px;
  border-radius: 5px;
  font-family: 'Roboto Condensed';
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  background-color: $primary;
  border: none;
  margin-left: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  letter-spacing: 1px;
}

.OtpSendEmailBtn {
  background-color: #44AF69 !important;
  padding: 0 16px 0 12px;
  height: 32px;
  border-radius: 5px;
  font-family: 'Roboto Condensed';
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  background-color: $primary;
  border: none;
  margin-left: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  letter-spacing: 1px;
}
